<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :selectable="false"
      :columns="columns"
      :type="type"
      :per-page="perPage"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: `certificate/${this.$route.params.MCR_id}/${this.$route.params.CM_id}/components`,
      addType: 'Add Components',
      addComponentName: 'add-components',
      editComponent: 'edit-components',
      viewContent: true,
      type: 'page',
      perPage: 50,
      columns: [
        { key: 'id', sortable: false },
        { key: 'name.ar', label: 'Components Name AR ' },
        { key: 'name.en', label: 'Components Name EN' },
        { key: 'sections', label: 'Sections' },
        { key: 'actions' },
      ],
    }
  },
  methods: {

  },
}
</script>

  <style>

  </style>
